var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',[_c('v-card',{staticClass:"ma-2",attrs:{"flat":""}},[_c('v-card-title',[_vm._v("Мои заказы")]),_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","sm":"6","md":"8"}},[_c('v-switch',{staticClass:"mt-5",attrs:{"label":"Показать выполненные заказы"},model:{value:(_vm.isShowAllOrders),callback:function ($$v) {_vm.isShowAllOrders=$$v},expression:"isShowAllOrders"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredItems,"loading":_vm.loading,"dense":"","disable-pagination":"","hide-default-footer":"","item-key":"id","sort-by":"id","sort-desc":""},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'OrderView', params: { id: item.id } }}},[_vm._v(" "+_vm._s(_vm._f("dateformat")(item.created))+" ")])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.amount,"USD"))+" ")]}},{key:"item.note",fn:function(ref){
var item = ref.item;
return [(item.note)?_c('v-tooltip',{attrs:{"bottom":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"blue"}},'v-icon',attrs,false),on),[_vm._v(" mdi-comment ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.cleanNote(item.note)))])]):_c('v-icon',{attrs:{"color":"gray"}},[_vm._v(" mdi-comment ")])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }